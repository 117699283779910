import React from "react";
import s from './QuoteItem.scss';
import { Image } from "components/contentful/image/Image";
import { RichText } from "components/contentful/rich-text/RichText";
import { Button } from "components/button/Button";

export const QuoteItem = ({item}: any) => {
    return (
        <div className={s('item')}
        >
        {
            item.avatar &&
            <div className={s('image')}>
                <Image image={item.avatar}/>
            </div>
        }
        {
            item.text &&
            <div className={s('textContainer', {alignLeft: item.avatar})}>
                {
                    item.text &&
                    <div className={s('text')}>
                        <RichText richText={item.text} quoted={true}/>
                    </div>
                }
                {
                    item.author &&
                    <span className={s('author')}>
                    - {item.author}
                    </span>
                }
                {
                    item.cta &&
                    <Button label={item.cta.label} href={item.cta.href}/>
                }
            </div>
        }
        </div>
    )
}