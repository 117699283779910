import { Container } from "components/container/Container";
import React from "react";
import s from "./QuoteCarousel.scss";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { QuoteItem } from "./QuoteItem";
import { Button } from "components/button/Button";
import NextIcon from "assets/svg/carousel/carousel-next.svg";
import PrevIcon from "assets/svg/carousel/carousel-prev.svg";
import "./carouselOverride.css";

export const Next = () => {
  return (
    <button className={s("button", "next")}>
      <NextIcon className={s("icon")} />
    </button>
  );
};

export const NextDesktopOnly = () => {
  return (
    <button className={s("button", "next", "desktopOnly")}>
      <NextIcon className={s("icon")} />
    </button>
  );
};

export const PrevDesktopOnly = () => {
  return (
    <button className={s("button", "prev", "desktopOnly")}>
      <PrevIcon className={s("icon")} />
    </button>
  );
};

export const Prev = () => {
  return (
    <button className={s("button", "prev")}>
      <PrevIcon className={s("icon")} />
    </button>
  );
};

export const QuoteCarousel = ({ list, style, mobileNomargin }: any) => {
  const items = list.items.map((item: any, i: number) => {
    if (item.quote) {
      return <QuoteItem item={item.quote} key={i} />;
    }
  });
  return (
    <Container>
      <div
        className={"quotes-carousel " + s("carousel") + { mobileNomargin }}
        style={style}
      >
        <AliceCarousel
          items={items}
          mouseTracking={!(items.length < 2)}
          infinite
          disableDotsControls={items.length < 2}
          responsive={{
            0: {
              items: 1
            }
          }}
          disableButtonsControls={items.length < 2}
          touchTracking={!(items.length < 2)}
          renderNextButton={NextDesktopOnly}
          renderPrevButton={PrevDesktopOnly}
        />
      </div>
      {list?.cta?.label && (
          <div>
            <div>
              {list?.cta?.label && (
                <div className={`${s("cta")} js-t-btn`}>
                  <Button
                    label={list?.cta.label}
                    href={list?.cta.file ? list?.cta.file.file.url : list?.cta.href}
                  ></Button>
                </div>
              )}
            </div>
          </div>
        )}
    </Container>
  );
};
